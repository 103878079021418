import {send} from "../index";

export function getConfigList(data, opts = {}) {
  //  配置列表
  return send({
    url: "/admin/dataDict/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function addConfig(data, opts = {}) {
  //  新增配置
  return send({
    url: "/admin/dataDict/add.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function editConfig(data, opts = {}) {
  //  编辑配置
  return send({
    url: "/admin/dataDict/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteConfig(data, opts = {}) {
  //  删除配置
  return send({
    url: "/admin/dataDict/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getConfigDetail(data, opts = {}) {
  //  配置详情
  return send({
    url: "/admin/dataDict/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getDict(data, opts = {}) {
  //  配置详情
  return send({
    url: "/admin/dataDict/getDict.do",
    method: "POST",
    data,
    ...opts,
  });
}
